@media print {
    button {
        display: none !important;
    }
    tr {
        page-break-inside: avoid;
    }
    table {
        border-collapse: separate;
    }
    .avoid-page-break {
        display: block !important;
        page-break-inside: avoid;
    }
    .page-break-before {
        display: block !important;
        page-break-before: always !important;
    }
    .page-break-after {
        page-break-after: always !important;
    }
    .page-break-inside {
        page-break-inside: avoid !important;
    }
}
